import React from 'react';
import './WhatupIcon.css';


const WhatupIcon = () => {
  return (
    <>
    <a href="https://wa.me/9494704184?text=Hello%2C%20Sandeep!" rel="noreferef noreferrer" target='_blank' style={{textDecoration:'none'}} className="btn-whatsapp-pulse">
	<i class="fab fa-whatsapp"></i>
</a>
    </>
  )
}

export default WhatupIcon